import React, { Component } from 'react';
import type { Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
import {cyan, grey, pink, purple, orange, yellow, blue} from '@mui/material/colors';
import Grid from '@mui/material/Grid';
//import Assessment from '@mui/icons-material/Assessment';
//import Face from '@mui/icons-material/Face';
//import ThumbUp from '@mui/icons-material/ThumbUp';
//import ShoppingCart from '@mui/icons-material/ShoppingCart';
import {
  Assessment,Face,ThumbUp,
  ShoppingCart,AddShoppingCart,RemoveShoppingCart,
  Wifi,WifiOff,Work,Today,DateRange,AccessTime,PieChart,
} from '@mui/icons-material'; 

import PageBase from '../site/PageBase';
import Filter from './Filter';
import InfoBox from './Info';
import Daily from './Daily';
import Recent from './Recent';
import Monthly from './Monthly';
import Feature from './Feature';
import Distribution from './Distribution';
import {bizpost} from '../../ajax';
import { ProgressDialog } from '../dialogs';
//import Complaints from './Complaints';
import Transactions from './Transactions';
import Rating from './Rating';
import Performance from './Performance';
import Compliance from './Compliance';
import Inspection from './Inspection';

const styles = (theme:Theme):any => ({
  root: {
    flexGrow: 1,
  },
  navigation: {
    fontSize: 15,
    fontWeight: 300,
    color: grey['600'],
    paddingBottom: 15,
    display: 'block'
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    marginBottom: 20,
    color:'white'
  },
  paper: {
    padding: 30
  },
  clear: {
    clear: 'both'
  }
});

const icons:any = {
  Assessment,Face,ThumbUp,
  ShoppingCart,AddShoppingCart,RemoveShoppingCart,
  Wifi,WifiOff,Work,Today,DateRange,AccessTime,PieChart,
};

const boards = [Transactions,Performance,Inspection,Compliance,Rating];

class Dashboard extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      dd: this.props.userData.dd,
      ping: null,
      timer: 20000, //300000,
      count:0,
      loading: false,
      isFiltered: false,
      tabid: 0, 
    };
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }
  componentDidMount(){
    const {timer} = this.state;
      var ping = setInterval(async()=>{
        try{
          const baseparams = {s:'security',a:'dashdata'};
          const response = await bizpost(baseparams);
          //console.log('dd resp',response);
          this.setState({dd:response.dd});
        }
        catch(error){
          console.log('dd error',error);
          this.sessionError(error);
        } 
      },timer);
      this.setState({ping:ping});
  }

  componentWillUnmount(){
    if(this.state.ping) clearInterval(this.state.ping);
  }

  sessionError(error:any){
    const {type,name,title,data} = error||{};
    if(type==='error' && name==='fetcherror' && title === 'Unauthorised'){
      const {failure,code} = data || {};
      if(failure === true && code === 401){
        //session expired. reload page.
        //this.props.doRestart('Session Expired. reloading...');
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    }
  }

  handleFilter = async (data:any) => {
    this.startLoading();
    const {start,end} = data;
    const baseparams = {s:'security',a:'dashfilter', sta:start, sto:end};
    const response = await bizpost(baseparams);
    this.stopLoading();
    this.setState({dd:response.dd});
    
  }

  getChannel(data:any){
    const {meta,customers:chandata} = data;
    const chans = meta.filter((val:any,idx:number,arr:any[])=> val&&val.group==='customers');
    const rtn = chans.reduce((acc:any[],rec:any,idx:number,arr:any) => {
      rec.value = (chandata===null)?'':chandata[rec.code];
      acc.push(rec);
      return acc;
    },[]);
    return rtn;
  }

  getMode(data:any){
    const {meta,mode:modedata={}} = data;
    const mdata = (modedata === null)?{}:modedata;
    const modes = meta.filter((val:any,idx:number,arr:any[])=> val&&val.group==='mode');
    const rtn = modes.reduce((acc:any[],rec:any,idx:number,arr:any) => {
      rec.value = (modedata===null)?'':mdata[rec.code];
      acc.push(rec);
      return acc;
    },[]);
    return rtn;
  }

  getInfo(data:any){
    //console.log('info.data',data);
    const {meta,source} = data;
    const infos = meta.filter((val:any,idx:number,arr:any[])=> val&&val.group==='info');
    const info = infos.reduce((acc:any[],rec:any,idx:number,arr:any) => {
      console.log('info.reduce.rec',rec,'source',source);
      rec.value = source[rec.name];
      console.log('info.reduce.rec',rec,'source',source);
      acc.push(rec);
      return acc;
    },[]);
    return info;
  }

  getDaily(data:any){

    return Daily;
  }

  getMonthly(data:any){

    return  Monthly;
  }

  getRecent(data:any){

    return Recent;
  }

  getFeature(data:any){

    return null;
  }

  onTabChange = (event:any,value:number) => {
    this.setState({tabid:value});
  }

  render() {
    const {userData,theme,classes} = this.props;
    const {bd} = userData;
    const {eni} = bd||{};
    const {dd, loading,tabid} = this.state;
    //const dashdata = dd || {monthly:[],source:[{}]};
    const dashdata = dd || {monthly:[],source:{}};
    //console.log('dashdata: ',dashdata);
    // const {total,mtnpush,mtnpull,vodapush,vodapull,other} = dashdata.source[0];
    // const infos = [
    //   {name:'total',title:'TOTAL',value:total,color:'#D81B60',palette:'pink',icon:ShoppingCart},
    //   {name:'mtnpull',title:'MTNPULL',value:mtnpull,color:'#00ACC1',palette:'cyan',icon:ShoppingCart},
    //   {name:'mtnpush',title:'MTNPUSH',value:mtnpush,color:'#8E24AA',palette:'purple',icon:ShoppingCart},
    //   {name:'vodapull',title:'VODAPULL',value:vodapull,color:'#FB8C00',palette:'orange',icon:ShoppingCart},
    //   {name:'vodapush',title:'VODAPUSH',value:vodapush,color:'#1E88E5',palette:'blue',icon:ShoppingCart},
    //   {name:'other',title:'OTHERS',value:other,color:'#757575',palette:'grey',icon:ShoppingCart},
    // ];
    //console.log('dd ',dd);
    const infos = this.getInfo(dashdata);
    const modes = this.getMode(dashdata);
    const chans = this.getChannel(dashdata);
    //console.log('infos: ',infos);
    const {meta,daily,monthly,recent,mode:feature} = dashdata;

    //console.log('render.daily: ',daily);
    //console.log('render.monthly: ',monthly);

    // const {infos,modes,chans} = this.props;
    // const {daily,monthly,recent} = this.props;

    const Board = boards[tabid]||boards[0];

    return (
      <PageBase navigation="PMS / Summary Dashboard" subid={(bd||{}).eni}>
        <div className={classes.root}>
        <Grid container spacing={theme.breakpoints.up('sm')?3:1}>
          <Grid item xs={12}>
            <Filter data={[]} tabid={tabid} handleFilter={this.handleFilter} onTabChange={this.onTabChange} icons />
          </Grid>
        </Grid>

        <Board 
          dd = {dd}
          eni = {eni}
          infos={infos}
          modes={modes}
          chans={chans}
          daily={daily}
          monthly={monthly}
          recent={recent}
        />
        {/* <h3 className={classes.navigation}>{this.props.userData.bd.enm}</h3> */}
{/* 
        <Grid container spacing={theme.breakpoints.up('sm')?3:1}>
          <Grid item xs={12}>
            <Filter data={[]} handleFilter={this.handleFilter} icons/>
          </Grid>
          {infos.map((info:any,idx:number) => (
            (info.title === 'OTHERS' && !info.value)?'':
          <Grid key={`infobox_${idx}`} item xs={12} sm={6} md={3} lg={3} xl={1}>
            <InfoBox Icon={icons[info.icon]}
                    color={info.color}
                    title={info.title}
                    value={info.value||0}/>
          </Grid>
          ))
          }
        </Grid>

        <Grid  container spacing={theme.breakpoints.up('sm')?3:1}>
          <Grid item xs={12} sm={6}>
            <Recent data={recent||[]} title='Recent Meters' icons/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Daily data={daily||[]} title='Daily Meters' handleFilter={this.handleFilter} icons/>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Monthly data={monthly||[]} title='Monthly Meters' icons/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Distribution data={modes||[]} title='Meters By Type' color='orange' icons/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Distribution data={chans||[]} title='Meters By Customers' color='blue' icons/>
          </Grid>
        </Grid> */}

        <ProgressDialog  
          open={loading} 
          loading={loading}
          startLoading={this.startLoading}
          stopLoading={this.stopLoading}
        />
      </div>
      </PageBase>
    );
  }
};

export default withStyles(styles, { withTheme: true })(Dashboard);
