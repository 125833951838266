import { Color, Theme } from "@mui/material";

const servicestyle = (theme:Theme,bgcolor:Color,fillcolor:string)=>{
  //console.log('service.theme:',theme);
  return ({
  container: {
    display: 'flex',
    // flexWrap: 'wrap',
  },
  dialog: {
    width: '100%',
    minHeight: 600,
  },
  winDialog: {
    width: '100%',
    minHeight: 600,
  },
  winPaper: {
    width: '100%',
    minHeight: 600,
  },
  titlebar: {
    padding:0,
    backgroundColor: theme.palette.secondary.main, //pink['300']
  },
  reqform: {
    width: 450
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 800,
  },
  preview: {
    width:200,
    height: 40,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  hide:{
    display:'none',
  },
  fixedfab: {
    backgroundColor:theme.palette.secondary.main, //pink['500'],
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    color:fillcolor
  },
  actionfab: {
    fill: fillcolor
  },
  saveButton: {
    marginLeft: 5
  },
  buttons: {
    fixed: {
      backgroundColor:bgcolor['500'], 
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 20,
      left: 'auto',
      position: 'fixed',
      color:fillcolor
    },
    action: {
      fill: fillcolor
    },
    save: {
      marginLeft: 5
    },
    cancel: {
      marginRight: 5
    } 
  },
  columns: {
    auto: {
      width: 'auto',
    },
    name: {
      width: '30%'
    },
    view: {
      width: '10%'
    },
    action: {
      width: '20px'
    }
  },
  //columnform
  columnform: {
    display: 'flex',
    flex:1,
    justifyContent: 'space-around'
  },
  columnfieldset: {
    flex: '1 1 0',
    marginRight: 10,
  }
});

}

export default servicestyle;
