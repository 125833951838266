import React, { Component, Fragment } from 'react';
import { Paper, type Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
import {cyan, grey, pink, purple, orange, yellow, blue} from '@mui/material/colors';
import Grid from '@mui/material/Grid';
//import Assessment from '@mui/icons-material/Assessment';
//import Face from '@mui/icons-material/Face';
//import ThumbUp from '@mui/icons-material/ThumbUp';
//import ShoppingCart from '@mui/icons-material/ShoppingCart';
import {
  Assessment,Face,ThumbUp,
  ShoppingCart,AddShoppingCart,RemoveShoppingCart,
  Wifi,WifiOff,Work,Today,DateRange,AccessTime,PieChart,
} from '@mui/icons-material'; 

//import PageBase from '../site/PageBase';
import Filter from './Filter';
import InfoBox from './Info';
import Daily from './Daily';
import Recent from './Recent';
import Monthly from './Monthly';
//import Feature from './Feature';
import Distribution from './Distribution';
//import {bizpost} from '../../ajax';
import { ProgressDialog } from '../dialogs';

import moment from 'moment';
import { ResponsiveContainer} from 'recharts';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L, { LatLngBoundsExpression } from 'leaflet';
//import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

//import PageBase from '../site/PageBase';
//import LiveMap from './LiveMap';
import {apipost, bizpost} from '../../ajax';
import { LatLngExpression } from 'leaflet';


const styles = (theme:Theme):any => ({
  root: {
    flexGrow: 1,
  },
  navigation: {
    fontSize: 15,
    fontWeight: 300,
    color: grey['600'],
    paddingBottom: 15,
    display: 'block'
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    marginBottom: 20,
    color:'white'
  },
  paper: {
    padding: 30
  },
  clear: {
    clear: 'both'
  },

  maproot: {
    flexGrow: 1,
    flexShrink: 1,
  },
  mapboardpaper: {
    backgroundColor: 'white',
    //maxHeight: 600,
    height: '100%',
    // height: 150
    marginLeft: 10,
    marginRight: 10,
  },
  mapdiv: {
    // height: 95,
    //padding: '5px 15px 0 15px'
  },
  mapheader: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    color: 'white',
    backgroundColor: purple['600'],
    padding: 10,
  }
});

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const icons:any = {
  Assessment,Face,ThumbUp,
  ShoppingCart,AddShoppingCart,RemoveShoppingCart,
  Wifi,WifiOff,Work,Today,DateRange,AccessTime,PieChart,
};

class Transactions extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      dd: this.props.dd,
      ping: null,
      timer: 20000, //300000,
      count:0,
      loading: false,
      isFiltered: false, 

      mapping: null,
      maptimer: 60000, //300000,
      //mapcount:0,
      lastread: null,
      recordset: [],
      previousset:[],
      currentset:[]
    };
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  // componentDidMount(){
  //   const {timer} = this.state;
  //     var ping = setInterval(async()=>{
  //       try{
  //         const baseparams = {s:'security',a:'dashdata'};
  //         const response = await bizpost(baseparams);
  //         //console.log('dd resp',response);
  //         this.setState({dd:response.dd});
  //       }
  //       catch(error){
  //         console.log('dd error',error);
  //         this.sessionError(error);
  //       } 
  //     },timer);
  //     this.setState({ping:ping});
  // }

  // componentWillUnmount(){
  //   if(this.state.ping) clearInterval(this.state.ping);
  // }

  sessionError(error:any){
    const {type,name,title,data} = error||{};
    if(type==='error' && name==='fetcherror' && title === 'Unauthorised'){
      const {failure,code} = data || {};
      if(failure === true && code === 401){
        //session expired. reload page.
        //this.props.doRestart('Session Expired. reloading...');
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    }
  }

  handleFilter = async (data:any) => {
    this.startLoading();
    const {start,end} = data;
    const baseparams = {s:'security',a:'dashfilter', sta:start, sto:end};
    const response = await bizpost(baseparams);
    this.stopLoading();
    this.setState({dd:response.dd});
    
  }

  getChannel(data:any){
    const {meta,customers:chandata} = data;
    const chans = meta.filter((val:any,idx:number,arr:any[])=> val&&val.group==='customers');
    const rtn = chans.reduce((acc:any[],rec:any,idx:number,arr:any) => {
      rec.value = (chandata===null)?'':chandata[rec.code];
      acc.push(rec);
      return acc;
    },[]);
    return rtn;
  }

  getMode(data:any){
    const {meta,mode:modedata={}} = data;
    const mdata = (modedata === null)?{}:modedata;
    const modes = meta.filter((val:any,idx:number,arr:any[])=> val&&val.group==='mode');
    const rtn = modes.reduce((acc:any[],rec:any,idx:number,arr:any) => {
      rec.value = (modedata===null)?'':mdata[rec.code];
      acc.push(rec);
      return acc;
    },[]);
    return rtn;
  }

  getInfo(data:any){
    //console.log('info.data',data);
    const {meta,input:source} = data;
    const infos = meta.filter((val:any,idx:number,arr:any[])=> val&&val.group==='info');
    const info = infos.reduce((acc:any[],rec:any,idx:number,arr:any) => {
      //console.log('info.reduce.rec',rec,'source',source);
      rec.value = source[rec.name];
      //console.log('info.reduce.rec',rec,'source',source);
      acc.push(rec);
      return acc;
    },[]);
    return info;
  }

  getDaily(data:any){

    return Daily;
  }

  getMonthly(data:any){

    return  Monthly;
  }

  getRecent(data:any){

    return Recent;
  }

  getFeature(data:any){

    return null;
  }

  //////////////////////////////map//////////////////////////////
  mapload = async(filename:string) => {
    try{
      const baseparams = {filename}; //,sta:date
      const response = await apipost('/api/resource/map','',baseparams);
      //this.setState({markers:response.sd});
      //this.map.addLayer(this.liveLayer);
      //this.liveLayer.clearLayers();
      //this.loadMakersLeaflet();
      return response; //TODO: return file
    }
    catch(error){
      console.log('map.bizpost error',error);
      return Promise.reject(error);
    } 
    //TODO: call LoadMarkers
    
  }

  baseload = async() => {
    try{
      return await this.mapload('ghana-regions.json');
    }
    catch(error){
      console.log('baseload error',error);
      return Promise.reject(error);
    } 
  }

  placesload = async() => {
    try{
      return await this.mapload('ghana-towns.json');
    }
    catch(error){
      console.log('placesload error',error);
      return Promise.reject(error);
    } 
  }

  merge = (p:string, ...arrs:any[]) => [].concat(...arrs).reduce((a, b) => !a.filter(c => b[p] === c[p]).length ? [...a, b] : a, []);


  liveLoad = async(code:any,date:any) => {
    try{
      const {recordset:previousset,lastread} = this.state;
      //const {userData} = this.props || {};
      //const {eni} = userData;
      const {eni} = this.props;
      //const start = moment.format()
      //TODO: I am hardcoding code and start until baseparams is properly fixed. start is a timestamp so it will 
      // not accept empty string. either null or a valid timestamp
      const baseparams = {s:'marker',a:'find',eni,stp:lastread}; //,sta:date, ,cod:'1M8wJEWIAcIEfILnCHpISD'
      const response = await bizpost(baseparams);
      const {sd:currentset} = response;
      const recordset = this.merge('rid',previousset,currentset);
      this.setState({recordset,previousset,currentset});
      //this.map.addLayer(this.liveLayer);
      //this.liveLayer.clearLayers();

      //this.loadMakersLeaflet();
    }
    catch(error){
      console.log('map.bizpost error',error);
    } 
    //TODO: call LoadMarkers
    
  }

  ttFormatTime = (stamp:any) => {
    return moment.unix(stamp).format('DD MMM');
  }

  

  //////////////////////////////////////////////////////////////

  render() {
    const {theme,classes} = this.props;
    const {userData} = this.props;
    //const {bd} = userData;
    const { loading} = this.state;
    // //const dashdata = dd || {monthly:[],source:[{}]};
    // const dashdata = dd || {monthly:[],source:{}};
    // //console.log('dashdata: ',dashdata);
    // // const {total,mtnpush,mtnpull,vodapush,vodapull,other} = dashdata.source[0];
    // // const infos = [
    // //   {name:'total',title:'TOTAL',value:total,color:'#D81B60',palette:'pink',icon:ShoppingCart},
    // //   {name:'mtnpull',title:'MTNPULL',value:mtnpull,color:'#00ACC1',palette:'cyan',icon:ShoppingCart},
    // //   {name:'mtnpush',title:'MTNPUSH',value:mtnpush,color:'#8E24AA',palette:'purple',icon:ShoppingCart},
    // //   {name:'vodapull',title:'VODAPULL',value:vodapull,color:'#FB8C00',palette:'orange',icon:ShoppingCart},
    // //   {name:'vodapush',title:'VODAPUSH',value:vodapush,color:'#1E88E5',palette:'blue',icon:ShoppingCart},
    // //   {name:'other',title:'OTHERS',value:other,color:'#757575',palette:'grey',icon:ShoppingCart},
    // // ];
    // //console.log('dd ',dd);
    const {infos,modes,chans} = this.props;
    // const infos = this.getInfo(dashdata);
    // const modes = this.getMode(dashdata);
    // const chans = this.getChannel(dashdata);
    //console.log('infos: ',infos);

    // const {meta,daily,monthly,recent,mode:feature} = dashdata;
    const {daily,monthly,recent} = this.props;

    // console.log('render.daily: ',daily);
    // console.log('render.monthly: ',monthly);



    const {title} = this.props;
    const {recordset} = this.state;
    //const {recordset,previousset,currentset} = this.state;
    //const {bd} = userData;
    const mapcenter:LatLngExpression = [8.977850, 39.253099]; //[7.5823, -1.9470];
    const apexpos:LatLngExpression = [9.01055, 38.74750]; //[6.647991, -0.579113];
    const apextext = `CBE. <br /> Event Location.`;
    //const bounds = new  L.LatLngBounds([[4.5392525, -3.260786],[11.1748562,	1.2732942]]);
    //const bounds:LatLngBoundsExpression = [[4.5392525, -3.260786],[11.1748562,	1.2732942]];
    //-3.24437008301, 4.71046214438, 1.0601216976, 11.0983409693
    const bounds:LatLngBoundsExpression = [[4.71046214438, -3.24437008301],[11.0983409693, 1.0601216976]];
    

    return (
      <Fragment>

        <Grid container spacing={theme.breakpoints.up('sm')?3:1}>
          {/* <Grid item xs={12}>
            <Filter data={[]} handleFilter={this.handleFilter} icons/>
          </Grid> */}

          {infos.map((info:any,idx:number) => (
            (info.title === 'OTHERS' && !info.value)?'':
          <Grid key={`infobox_${idx}`} item xs={12} sm={6} md={3} lg={3} xl={1}>
            <InfoBox Icon={icons[info.icon]}
                    color={info.color}
                    title={info.title}
                    value={info.value||0}/>
          </Grid>
          ))
          }
        </Grid>

        <Grid  container spacing={theme.breakpoints.up('sm')?3:1}>
          <Grid item xs={12} sm={6}>
            <Recent data={recent||[]} title='Recent Transactions' icons/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Daily data={daily||[]} title='Daily Transactions' handleFilter={this.handleFilter} icons/>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Monthly data={monthly||[]} title='Monthly Transactions' icons/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Distribution data={modes||[]} title='Transactions By Type' color='orange' icons/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Distribution data={chans||[]} title='Transactions Distribution' color='blue' icons/>
          </Grid>
        </Grid>


        <Grid container spacing={theme.breakpoints.up('sm')?3:1}>
          <Grid item xs={12}>
            {/* <LiveMap /> */}
            <Paper className={classes.mapboardpaper}>
              <div className={classes.mapheader}>{title}</div>
              <div className={classes.mapdiv}>
                <ResponsiveContainer >
                  <MapContainer center={mapcenter} zoom={7} scrollWheelZoom={false} style={{height:'80vh',width:'100%'}} bounds={bounds}>
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" bounds={bounds}
                    />
                    <Marker position={apexpos}>
                      <Popup>
                        {apextext}
                      </Popup>
                    </Marker>
                    {recordset.map((record:any, idx:number) => 
                      <Marker key={record.rid} position={record.pos}>
                      <Popup>
                        <span>{record.txt}</span>
                      </Popup>
                    </Marker>
                    )}
                  </MapContainer>
                </ResponsiveContainer>
              </div>
            </Paper>
            
          </Grid>

          
        </Grid>

        <ProgressDialog  
          open={loading} 
          loading={loading}
          startLoading={this.startLoading}
          stopLoading={this.stopLoading}
        />

      </Fragment>
    );
  }
};

export default withStyles(styles, { withTheme: true })(Transactions);
