//react
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//material-ui design helpers
//import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
//material-ui core components
import Fab from '@mui/material/Fab';
//icons
import ContentAdd from '@mui/icons-material/Add';

//local helpers
import {bizpost} from '../../ajax';

//local components
import ProgressDialog from '../dialogs/ProgressDialog';

import GridPanel from '../grid/GridPanel';
import {tuples,ucfirst,clone} from '../../core/utils';
import {formatDate,formatSource} from '../renderers';
//import {setConfig} from '../../core/serviceconfig';
import {setConfig} from '../../core';
//import Data from '../base/data';
import {Data} from '../base';

//siblings
import styles from '../../app/appstyles';
import {ServiceWindow} from '.';
//import ServiceWindow from './';

/**
 * -------------------------------------------------------------------
 * Main Class with main view - Grid
 * -------------------------------------------------------------------
 */
class ServiceBase extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    const {title,service} = this.props;
    console.log('construct title,service',title,service);
    this.state = {
      title: ucfirst(title||service), //TODO: null, get from props
      //service: service, //TODO: null, get from props
      url: '/biz',
      params: {},
      action: '', //'search': can be add,edit,delete,search,...other
      actions: [],
      filter: {},
      loading: false,
      wizmode:'add',
      record:{},
      data: [],
      recordCount: 0,
      page: 0,
      rowsPerPage: 10,
      pageMode: 'local',
      order: 'desc',
      orderBy: 'dcd',
      refid: null,
      fwopen: false
    };
  }

  //base config for services
  configuration = () => {
    const config = {
      service:{},
      create:true,
      actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View',man:'Manage',manage:'Manage',process:'Process',wiz:'Process',close:'Close',retry:'Retry',check:'Check',print:'Print'},
      panels: { //simple,dialog,resview,co      
        add:'simple',edit:'simple',delete:'dialog',view:'simple',man:'tab',wiz:'wizard',close:'dialog',retry:'dialog',check:'dialog',print:'dialog'
      }
    };
    return config;
  }

  getServiceConfig = () => {
    const {configuration} = this.props;

    const extraconfig = Object.assign({},this.configuration(),configuration());
    const config = setConfig(extraconfig); 
    const ServiceConfig = clone(config);
    const SData = ServiceConfig.service; 
    return {svc:ServiceConfig,svd:SData};
  }

  setPageMode = () => {
    const {svc,svd} = this.getServiceConfig();
    const {pageMode='local'} = svd; 
    console.log('setPageMode.pagemode:...',pageMode,svd);
    this.setState({pageMode}); 
  }

  componentDidMount(){
    // const {svc,svd} = this.getServiceConfig();
    // const {pageMode='local'} = svd; 
    // console.log('mounted.pagemode:...',pageMode,svd);

    // this.setState({pageMode});  
    this.setPageMode();
    console.log('refreshing...');
    this.refresh();
  }

  filterColumns = (rows:any[]) => {
    // const {rows} = this.props;
    const cols:any[] = rows.map((row:any)=>row.id);//this.state;
    return rows.filter((row:any)=>cols.includes(row.id));
  }

  setColumns = (event:any,fcols:any) => {
    this.setState({cols:fcols});
  }

  componentDidUpdate(prevProps:any,prevState:any){
    console.log('component updated');
    if(prevProps.service !== this.props.service){
      this.setPageMode();
      this.refresh();
    }
    else if(prevState.page !== this.state.page){
      console.log(`SB.oldpage:${prevState.page}, newpage:${this.state.page}`);
      if(this.state.pageMode === 'remote'){
        this.refresh();
      }
    }
  }

  refresh = async(inparams?:any) => {
    console.log('refresh called');
    try{
      const {svc,svd} = this.getServiceConfig();
      const {pageMode='local'} = svd;
      // //TODO: store final paramlist in state on success for reuse of refresh
      const {params,rowsPerPage,page} = this.state;
      //const {code,service} = this.props;
      const {service} = this.props;

      this.startLoading();
      const pos = page * rowsPerPage;
      const plm = rowsPerPage;
      const paging = (pageMode === 'remote')?{pos,plm}:{};
      let extraparams = inparams||params; //{}
      let baseparams = {s:service,a:'find',...extraparams,...paging};/*shc:code,/*,pos:pos,plm:plm*/
      const response = await bizpost(baseparams);
      console.log('service response ',response);
      //TODO: build rows and add to state??
      this.setState({data:response.sd,recordCount:+response.rc});//,params:baseparams
    }
    catch(error){
      // console.log(error);
      this.setState({data:[]});
      this.setState({recordCount:0});
      console.log('refresh error:',error);
      const errorx:any = error||{};
      this.props.showNotification(errorx.message||"request failed",errorx.type||'warn',errorx.title||'Loading Error');
    }
    finally{
      if (this.state.loading) this.stopLoading();
    }
  }

  onSearch = async(service:string,query:string) => {
    console.log('search called');
    try{
      //TODO: store final paramlist in state on success for reuse of refresh
      const {params} = this.state;
      //const {code,service} = this.props;
      const {service} = this.props;
      this.startLoading();
      let extraparams =  {qry:query,...params}; //params||params; //{}
      let baseparams = {s:service,a:'search',...extraparams};/*shc:code,/*,pos:pos,plm:plm*/
      const response = await bizpost(baseparams);
      console.log('service response ',response);
      //TODO: build rows and add to state??
      this.setState({data:response.sd,recordCount:+response.rc});//,params:baseparams
    }
    catch(error){
      // console.log(error);
      this.setState({data:[]});
      this.setState({recordCount:0});
      console.log('search error:',error);
      const errorx:any = error||{};
      this.props.showNotification(errorx.message||"request failed",errorx.type||'warn',errorx.title||'Loading Error');
    }
    finally{
      if (this.state.loading) this.stopLoading();
    }
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  formatStatus = (type:string) => (id:any) => {
    let name = ['disabled','active','stopped'][id] || 'unknown'; 
    return name;
  }

  statusname = (id:any) => {
    return Data.statuses.list[id] || 'unknown';
  }

  rowActions = (id:any,rec:any) => {
    return ([['view','delete'],
             ['view','edit','delete'],
             ['view','edit','delete']][rec.sts]||['view']);
  }

  isAddEnabled = (actions:any,create:any) => {
    return (Array.isArray(actions))?actions.includes('add'):create===true;
  }

  handleRecord = (record:any) => () =>{
    console.log('in handleRecord',record.action);
    const actions = this.props.actionSet || {};
    const {url} = this.state;
    let action = actions[record.action] || Data.actions[record.action] || 'Process';
    const {handleRecord} = this.props;
    const xrecord = (typeof handleRecord == 'function')?handleRecord(record):record;
    if(xrecord) this.setState({record:xrecord.item,wizmode:xrecord.action,action:action,url:xrecord.url||url,fwopen: true});
  }

  handleDelete = (record:any) => () =>{
    this.setState({record:record,wizmode:'delete',fwopen: true});
  }

  handleClose = () => {
    this.setState({fwopen:false});
  }

  onResponse = (status:any,response:any) => {
    // const {wizmode} = this.state;
    console.log('onResponse',response);
    //TODO
    if(status) {
      this.handleClose();
      this.refresh();
    }
  }

  // handleGridSubmit = (status,response) => {
  //   console.log('in handleGridSubmit');
  //   //TODO
  //   if(status) {
  //     //this.handleClose();
  //     this.refresh();
  //   }
  // }

  onPageChange = (event:any, page:any) => {
    console.log('in SB.onPageChange',page);
    this.setState({ page });
    //this.refresh();
  };

  onRowsPerPageChange = (event:any) => {
    console.log('in SB.onRowsPerPageChange');
    this.setState({ rowsPerPage: event.target.value });
  };

  render = () => {
    //console.log('in render');
    const {code,title,service,configuration,actionSet,rowActions,onSubmit,showNotification,classes,...others} = this.props;
    const {formformatters,gridformatters,onChange} = this.props;
    const {url,action,loading,wizmode,fwopen,record,data,recordCount,pageMode:pmode,page,rowsPerPage,order,orderBy} = this.state;
    const {search,query,startSearch,stopSearch} = this.props;
    if(search === true) {
      this.onSearch(service,query);
      if(typeof stopSearch === 'function') stopSearch();
    }
    //console.log(url,action,loading,wizmode,fwopen,record,data,recordCount,page,rowsPerPage,order,orderBy);
    //console.log('render title,service',this.props.service);
    //const title = ucfirst(this.props.title||service);

    //const fnConfig = configuration || this.configuration;
    //const config = fnConfig();//console.log('configuration:',config);
    
    //console.log('configuration',service,configuration());
    const extraconfig = Object.assign({},this.configuration(),configuration());
    //console.log('extraconfig',service,extraconfig);
    const config = setConfig(extraconfig); //
    //console.log('config',service,config);
    const ServiceConfig = clone(config);
    //console.log('ServiceConfig',service,ServiceConfig);
    const SData = ServiceConfig.service; //ServiceData['service'];
    if(!SData) this.props.setHome();
    const stitle = ucfirst(SData.title||service);
    const {pageMode='local'} = SData;
    console.log(`pageMode:${pageMode},pmode:${pmode}`);
    const cancreate = this.isAddEnabled(rowActions,ServiceConfig.create);
    const panels = ServiceConfig.panels; 
    
    const Columns = tuples(SData.grid);
    if(Columns.length>0) Columns.push({k:'ACTION',v:''});
    //console.log('tuples',Columns,Columns.length);
    const cm = SData.cols;
    const fmformatters = {sts:this.formatStatus('form'),dcd:formatDate,stp:formatDate,src:formatSource};
    const gdformatters = {sts:this.formatStatus('grid'),dcd:formatDate,stp:formatDate,src:formatSource};
    //add formatters from config and from class to base formatters
    const cformatters = ServiceConfig.formatters || {};
    const {form:cfformatters,grid:cgformatters} = cformatters;
    const _formformatters = Object.assign({},fmformatters,cfformatters,formformatters||{});
    const _gridformatters = Object.assign({},gdformatters,cgformatters,gridformatters||{});
    const _rowActions = rowActions||this.rowActions;
    //const _handleRecord = this.handleRecord;// || this.handleRecord;
    const newrecord = {item:{sts:1,a:'add'},action:'add'};
    // console.log('service.classes',classes);
    // console.log('service.theme',theme);
    const isform = (action !== '');
    //console.log('isform',isform);
    //console.log('action',action,wizmode,isform);
    return (
      <div>
        {cancreate && 
        <Fab 
          className={classes.fixedfab} 
          onClick={this.handleRecord(newrecord)}
        >
          <ContentAdd className={classes.actionfab}/>
        </Fab> 
        }
        {(isform && fwopen) &&
        <ServiceWindow 
          open={fwopen} 
          mode={wizmode} 
          code={code}
          title={`${action} ${stitle}`} 
          service={service}
          url={url}
          panels={panels}
          actions={_rowActions}
          actionSet={actionSet}
          rowActions={rowActions}
          record={record} 
          data={data} 
          sdata={SData}
          sconfig={ServiceConfig}
          formatters={_formformatters}
          onChange={onChange}
          refresh={this.refresh}
          onSubmit={onSubmit}
          onResponse={this.onResponse}
          onClose={this.handleClose.bind(this)} 
          showNotification={showNotification}
          {...others}
        />
        }
        { loading &&
        <ProgressDialog  
          open={loading} 
          loading={loading}
          startLoading={this.startLoading} //.bind(this) 
          stopLoading={this.stopLoading} //.bind(this)
        />
        }
        { (!fwopen) &&
        <GridPanel //TODO: Add service and code for remote activity?
          title={stitle}
          sconfig={ServiceConfig}
          sdata={SData}
          rows={cm}
          data={data}
          rowsPerPage={rowsPerPage}
          page={page}
          pageMode={pageMode}
          order={order}
          orderBy={orderBy}
          recordCount={recordCount}
          formatters={_gridformatters}
          actions={_rowActions}
          actionSet={actionSet}
          rowActions={rowActions}
          refresh={this.refresh}
          setColumns={this.setColumns}
          filterColumns={this.filterColumns}
          handleSelect={this.handleRecord.bind(this)}
          onAction={this.handleRecord.bind(this)}
          onRowsPerPageChange={this.onRowsPerPageChange}
          onPageChange={this.onPageChange}
          {...others}
        />
        }
      </div>
    );
  };
};

ServiceBase.propTypes = {
  service: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  configuration: PropTypes.func,
  rowActions: PropTypes.func,
  filterColumns:PropTypes.func,
  formformatters: PropTypes.object,
  gridformatters: PropTypes.object,
  setPage: PropTypes.func,
  setHome: PropTypes.func,
  showNotification: PropTypes.func
};

//Service = withStyles(styles, { withTheme: true })(Service);
export default withStyles(styles, { withTheme: true })(ServiceBase);
 